const menu = [
  {
    id: 1,
    title: 'FULL SET OF DIPPING POWDER (WHITE OR CLEAR TIP WITH GEL CLEAR) ',
    category: 'NAIL EXTENSIONS',
    price: 70,
    img: './images/item-1.jpeg',
    desc: '',
  },
  {
    id: 2,
    title: 'FULL SET OF DIPPING POWDER WITH GEL COLOUR ',
    category: 'NAIL EXTENSIONS',
    price: 75,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'FULL SET OF DIPPING POWDER WITH NORMAL COLOUR',
    category: 'NAIL EXTENSIONS',
    price: 68,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'DIPPING COLOUR POWDER ON REAL NAILS (NO TIP, NO TAKE OFF) ',
    category: 'NAIL EXTENSIONS',
    price:68,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'INFILL WITH NORMAL NAIL POLISH ',
    category: 'INFILL',
    price: 58,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'INFILL WITH GEL CLEAR ',
    category: 'INFILL',
    price: 60,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'INFILL WITH GEL COLOR ',
    category: 'INFILL',
    price: 65,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'NORMAL NAIL POLISH ',
    category: 'MANICURE ',
    price: '40',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'GEL COLOUR NAIL POLISH ',
    category: 'MANICURE ',
    price: '50',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'NORMAL COLOUR NAIL POLISH ',
    category: 'SPA PEDICURE',
    price: '55',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'GEL COLOUR NAIL POLISH (TAKE OFF COLOUR PLUS $3) ',
    category: 'SPA PEDICURE',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'NORMAL COLOUR NAIL POLISH FOR HANDS/TOES ',
    category: 'BUFF AND SHAPE',
    price: '28/33',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 15,
    title: 'GEL COLOUR NAIL POLISH FOR HANDS/TOES (TAKE OFF COLOUR PLUS $3) ',
    category: 'SPA PEDICURE',
    price: '40/43',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 16,
    title: 'NAILS EXTENSIONS',
    category: 'NAILS REMOVAL',
    price: '25',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'GEL COLOUR',
    category: 'NAILS REMOVAL',
    price: '18',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'FIXING',
    category: 'NAILS REMOVAL',
    price: '8',
    img: './images/item-9.jpeg',
    desc: ``,
  },
];
export default menu;
