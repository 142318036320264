import React, { Component } from "react";
import { Container, Row, Col, CardImg } from "reactstrap";
// import Timeline from "../../assets/img/timeline.png";
import about from "./about.jpg";
import about2 from "./about2.jpg";
import about3 from "./about3.jpg";
import about4 from "./about4.jpg";
import "./About.css";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

class About extends Component {
  state = {
    content: <Loading text="About us..." />,
  };

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <Container
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%" }}
            >
              <Row>
                <Col xs="12" sm="12" className="animated bounceIn ">
                  <h1
                    className="mt-5"
                    style={{ fontSize: "72px", color: "#ffab9d" }}
                  >
                    About Us{" "}
                  </h1>
                  <p className="textdesc">
                    For myself, I really love do my own nail for different
                    style, different color and some art. I purchased lots of
                    nail product and hundreds of nail polish. I also interested
                    in doing nail for my families and friends. I worked in the
                    nail field for a long time and I have the clearly understand
                    in this field. I learned the professional technology both in
                    China and New Zealand. I want to bring some new style nail
                    design, art and technology from China to New Zealand. So I
                    will put the focus on bringing New Zealand some fresh blood
                    in nail field. I also import lots of high-quality products
                    from Japan and we could do all kinds of nail whatever you
                    want. Our team will use the best quality of products, and
                    provide the customer the professional, fashion, passion and
                    elegant service.
                  </p>
                  <a
                    target="_blank"
                    href="https://www.fresha.com/a/diamond-nails-blenheim-blenheim-14-maxwell-road-sykp7v6i/booking?menu=true"
                  >
                    <button class="glowing-btn">
                      <span class="glowing-txt">
                        B<span class="faulty-letter">O</span>OK
                      </span>
                    </button>
                  </a>
                </Col>
                <Col xs="12" className="animated flipInY delay-1s mt-5">
                  <div className="row1">
                    <div className="column1">
                      <img src={about} />
                      <img src={about2} />
                    </div>
                    <div className="column1">
                      <img src={about3} />
                      <img src={about4} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ),
        }),
      3000
    );
  }
  render() {
    return this.state.content;
  }
}

export default About;
