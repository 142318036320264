import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';



const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
		link: 'https://www.facebook.com/'
	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
		link: 'https://www.instagram.com/'
	},
	// {
	// 	name: 'YouTube',
	// 	icon: iconStyle(FaYoutube),
	// 	link: 'https://www.youtube.com/'
	// },
	// {
	// 	name: 'Twitter',
	// 	icon: iconStyle(FaTwitter),
	// 	link: 'https://twitter.com/'
	// },
	// {
	// 	name: 'LinkedIn',
	// 	icon: iconStyle(FaLinkedin),
	// 	link: 'https://nz.linkedin.com/'
	// },
];

export const footerData = [
	{
		title: 'Diamond Nails',
		links: ['14 Maxwell Road', 'Blenheim, New Zealand', '+ 64 3578 4890'],
	},
	{
		title: 'Diamond Beauty',
		links: ['13 Charles Street', 'Blenheim, New Zeland', '+64 210 890 7408', '(...comming soon)'],
	},
	{
		title: 'Diamond Nails & Beauty',
		links: ['22 High Street', 'Picton, Marlborough', '+64 212 350 676', '(...comming soon)'],
	},
	// {
	// 	title: 'Legal',
	// 	links: ['GDPR', 'Privacy Policy', 'Terms of Service', 'Disclaimer'],
	// },
];

export const OperhourData = [
	{
		title: 'Location 1',
		title2: 'Diamond Nails',
		links: [ '14 Maxwell Road', 'Blenheim, New Zealand','+ 64 3578 4890', 'Opening hours','Monday-Saturday',' 9am-6pm','After 6pm ','booking only for ','massage'],
	},
	{
		title: 'Location 2',
		title2: 'Diamond Beauty',
		links: ['13 Charles Street', 'Blenheim, New Zealand','+ 64 2108 9074 08', 'Opening hours','Monday-Saturday',' 9am-7pm','After 6pm & ','Sunday 11am - 4 pm','booking only for',' massage'],
	},
	{
		title: 'Location 3',
		title2: 'Diamond Nails & Beauty Picton',
		links: ['22 High StreetPicton', 'Marlborough, New Zealand','+ 64 212 350 676', 'Opening hours','Monday-Saturday',' 9am-6pm','Sunday: 11am-4pm (summer)','/ close (winter) '],
	},
];
