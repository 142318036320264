import React, { useState, useEffect } from "react";
import {
  Button,
  Column,
  Container,
  Row,
  Section,
  TextWrapper,
} from "../../globalStyles";
import {
  FooterForm,
  FooterInput,
  FooterLogo,
  SocialIcon,
  WebsiteRights,
  FooterColumn,
  FooterSocialIcon,
  FooterRow,
  FooterLine,
  SectionFooter,
  ImageFooter
} from "./FooterStyles";
import { footerData, footerSocialData } from "./footerData";
import { AnimatePresence } from "framer-motion";
import logotower from "../../assets/tower/logotower.png";
import Loading from "../Loading/Loading";
import about from "./about.jpg";

function Footer() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);
  return (
    <>
      <AnimatePresence>
        {loaded ? (
          <SectionFooter>
            <Column
              justify="center"
              align="center"
              textAlign="center"
              mb="1.2rem"
              padding="1.2rem"
            >
              <h1
                className="animated rubberBand"
                style={{
                  fontSize: "72px",
                  color: "#a84837",
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  fontWeight: 600,
                }}
              >
                Contact Us
              </h1>
              <TextWrapper
                size="2rem"
                weight="700"
                mb="1.2rem"
                smSize="1.2rem"
                color="#000"
                className="animated bounceIn"
              >
                Call us:{" "}
                <a
                  href="tel: +6439725437"
                  style={{ color: "#a84837", textDecoration: "none" }}
                >
                  +64 39725437{" "}
                </a>
              </TextWrapper>
              <TextWrapper
                size="2rem"
                weight="700"
                mb="1.2rem"
                smSize="1.2rem"
                color="#000"
                className="animated bounceIn"

              >
                Email us:{" "}
                <a
                  href="mailto:booking@towerbeauty.co.nz"
                  style={{ color: "#a84837", textDecoration: "none" }}
                >
                  booking@towerbeauty.co.nz{" "}
                </a>
              </TextWrapper>

              <TextWrapper mb="0rem" spacing="1px" color="#000" weight="700" size="1.5rem" smSize="1rem"                 className="animated bounceIn"
>
                Shop 2B, Tower Junction Village
              </TextWrapper>
              <TextWrapper mb="1.5rem" spacing="1px" color="#000" weight="300" size="1rem" smSize="1rem"                 className="animated bounceIn"
>
                2/4 Troup Drive, Addington, Christchurch, 8011,New Zealand
              </TextWrapper>
              <ImageFooter src={about} className="animated jackInTheBox"/>
            </Column>
            <Container>
              <FooterLine className="animated rollIn" >
                <FooterLogo to="/">
                  <SocialIcon src={logotower} style={{ width: "100px" }} />
                </FooterLogo>
                <WebsiteRights>
                  Diamond Nail Beauty © 2023 designed by{" "}
                  <a
                    target="_blank"
                    href="https://miraelite.com/"
                    style={{
                      textDecoration: "none",
                      color: "#a84837",
                      fontWeight: 600,
                    }}
                  >
                    Mira Elite
                  </a>{" "}
                  with ❤️
                </WebsiteRights>
                <Row justify="center" gap="2rem" align="center" width="200px" >
                  {footerSocialData.map((social, index) => (
                    <FooterSocialIcon
                      key={index}
                      href={social.link}
                      target="_blank"
                      aria-label={social.name}
                      style={{color: "#a84837"}}
                    >
                      {social.icon}
                    </FooterSocialIcon>
                  ))}
                </Row>
              </FooterLine>
            </Container>
          </SectionFooter>
        ) : (
          <Loading text="Contact us..." />
        )}
      </AnimatePresence>
    </>
  );
}

export default Footer;
