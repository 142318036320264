import React, { useState, useEffect } from "react";
import { FacebookProvider, Page, Group, EmbeddedPost } from "react-facebook";
import "./fb.css";
import background from "./light-yellow.jpg";
import Loading from "../Loading/Loading";
import { AnimatePresence } from "framer-motion";


const Fbpost = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);
  return (
    <>
    <AnimatePresence>
      {loaded ? (
    <div
      className="fb-container"
      id="facebook"
      // style={{ backgroundImage: `url(${background})` }}
    >
      <h1
                className="animated flash "
                style={{
                  fontSize: "60px",
                  color: "#a84837",
                  textAlign: "center",
                  margin: "3rem 0",                  
                  fontWeight: 500,
                }}
              >
                Follow us on Facebook{" "}
              </h1>
      <a
        href="https://www.facebook.com/towernailsalon/?ref=page_internal"
        target="_blank"
        className="animated bounceIn "
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#000",
        }}
      >
        #towerbeauty
      </a>

      <FacebookProvider appId="211013638396718">
        <Page
          href="https://www.facebook.com/towernailsalon/?ref=page_internal"
          tabs="timeline"
          height={750}
        />
        {/* <Group
          href="https://www.facebook.com/groups/375934682909754"
          width="500"
          showSocialContext={true}
          showMetaData={true}
          skin="dark"
        /> */}
        {/* 
        <EmbeddedPost
          href="https://www.facebook.com/photo?fbid=384204737153324&set=a.384206390486492"
          width="500"
        /> */}
      </FacebookProvider>
    </div> ) : (
      <Loading text="Facebook page..." />
    )}
    </AnimatePresence>
    </>
  );
};

export default Fbpost;
