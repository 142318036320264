import React, { Component } from "react";
import { Container, Row, Col, CardImg } from "reactstrap";
import logotower from "../../assets/tower/logotower_large.png";

import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import Clock from "../Clock/Clock";
import "./Home.css";
import bg from "./bg.jpg";
import { fesha_url } from "../../fresha_url";

class Home extends Component {
  state = {
    content: <Loading text="Welcome loading..." />,
  };

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          content: (
            <div
              style={{
                width: "100%",
                height: "100vh",
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url(${bg}) center`,
              }}
            >
              <Container
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Row
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: "100%", minHeight: "100%" }}
                >
                  <Col
                    className="animated bounceInDown title__info"
                    sm="12"
                    md="6"
                    style={
                      {
                        // border: "1px solid red",
                      }
                    }
                  >
                    <h1 className="font_welcome">Welcome to</h1>
                    <h1 className="font_gradient">Tower Nails & Beauty</h1>
                    <h6
                      style={{
                        color: "white",
                        letterSpacing: "2px",
                        textAlign: "center",
                      }}
                    >
                      This is one of the biggest and well-equipped nail salons
                      in Christchurch with updated techniques & quality
                      products. We offer nail treatments, eyelashes, tinting,
                      and waxing at the most affordable price
                    </h6>
                    <div style={{ display: "flex" }}>
                      <a href={fesha_url} class="cta" target="_blank">
                        <span>Book now</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                          <path d="M1,5 L11,5"></path>
                          <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                      </a>
                    </div>
                    <div style={{ color: "white", textAlign: "center" }}>
                      <p>Opening hours</p>
                      <p>Mon-Sat: 9:00am - 6:00pm</p>
                      <p>Sun & Public Holiday: 10:00am - 5:00pm</p>
                    </div>
                  </Col>
                  <Col
                    className="brandlogo"
                    sm="12"
                    md="6"
                    style={
                      {
                        // border: "1px solid green",
                      }
                    }
                  >
                    <CardImg
                      className="animated flipInY delay-1s brandlogo__img"
                      src={logotower}
                      alt="logo"
                      style={
                        {
                          // border: "1px solid yellow",
                        }
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          ),
        }),
      3000
    );
  }
  render() {
    return this.state.content;
  }
}

export default Home;
