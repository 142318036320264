const menu = [
  {
    id: 1,
    title: 'NEW SET ',
    category: 'EYELASH EXTENSION - CLASSIC',
    price: 85,
    img: './images/item-1.jpeg',
    desc: ``,
  },
  {
    id: 2,
    title: 'REFILL (8-14 DAYS) ',
    category: 'EYELASH EXTENSION - CLASSIC',
    price: 'from $50',
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 3,
    title: 'NEW SET',
    category: 'EYELASH EXTENSION - 3-4D',
    price: 155,
    img: './images/item-3.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'REFILL (8-14 DAYS)',
    category: 'EYELASH EXTENSION - 3-4D',
    price: 'from $75',
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: '*EYELASH REMOVAL ONLY',
    category: 'EYELASH EXTENSION - 3-4D',
    price: 28,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'EYELASH LIFT',
    category: 'EYELASH EXTENSION - 3-4D',
    price: 65,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'EYEBROWN TINT',
    category: 'EYELASH EXTENSION - TINTING',
    price: 18,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'EYELASH TINT',
    category: 'EYELASH EXTENSION - TINTING',
    price: 23,
    img: './images/item-8.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: '1/2 LEG',
    category: 'Waxing',
    price: 33,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: '3/4 LEG',
    category: 'Waxing',
    price: '43',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'FULL LEG',
    category: 'Waxing',
    price: '53',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: '1/2 ARM',
    category: 'Waxing',
    price: '23',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'UNDER ARM',
    category: 'Waxing',
    price: '23',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 131,
    title: 'FULL ARM',
    category: 'Waxing',
    price: '33',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'EYE BROW',
    category: 'Waxing',
    price: '18',
    img: './images/item-9.jpeg',
    desc: ``,
  },

  {
    id: 15,
    title: 'UPPER LIP',
    category: 'Waxing',
    price: '18',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 16,
    title: 'CHIN',
    category: 'Waxing',
    price: '18',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'UPPER LIP & CHIN',
    category: 'Waxing',
    price: '32',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'ALL FACE',
    category: 'Waxing',
    price: '63',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 19,
    title: 'BRAZILLIAN',
    category: 'Waxing',
    price: '68',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 20,
    title: 'BIKINI LINE',
    category: 'Waxing',
    price: '38',
    img: './images/item-9.jpeg',
    desc: ``,
  },
    
];
export default menu;
