import React from "react";
import "./Nails.css"

const Menu = ({ items }) => {
  return (
    <div className="section-center">
      {items.map((item) => {
        const { id, title, img, desc, price } = item;
        return (
          <article key={id} className="menu-item animated bounceIn delay-2s">
            {/* <img src={img} alt={title} className="photo" /> */}
            <div className="item-info">
              <header>
                <div className="left-text">
                  <h4>{title}</h4>
                  <p className="item-text">{desc}</p>
                </div>
                <h4 className="price_nails">{price}</h4>
              </header>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Menu;
