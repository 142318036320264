import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Nails from "./components/NailsMenu/Nails";
import Beauty from "./components/BeautyMenu/Beauty";
import Particles from "./components/Particles/ParticlesComponent";
import Experiments from "./components/Experiments/Experiments";
import Footer from "./components/Footer/Footer";
import Fbpost from "./components/Fbchatplusgin/Fbpost"
import Fbchat from "./components/Fbchatplusgin/Fbchat"
import Instagram from "./components/Instagrams/instagram"

import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <div
          style={{
            paddingLeft: "60px",
            width: "100%",
            minHeight: "100%",
            backgroundColor: "#222",
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Navbar />
          {/* <Particles /> */}
          <div className="App">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" exact component={About} />
              <Route path="/nails" exact component={Nails} />
              <Route path="/beauty" exact component={Beauty} />
              <Route path="/facebook" exact component={Fbpost} />
              <Route path="/instagram" exact component={Instagram} />

              <Route path="/contact" exact component={Footer} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
