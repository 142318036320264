import React, { useState, useEffect } from "react";
import "./Beauty.css";
import items from "./dataBeauty";
import MenuBeauty from "./MenuBeauty";
import CategoriesBeauty from "./CategoriesBeauty";
import background from "./black-paper-3.jpg";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  ContentButton,
  ContentColumn,
} from "./ContentStyles";
import { Container } from "../../globalStyles";
import Loading from "../Loading/Loading";
import { AnimatePresence } from "framer-motion";

const allCategories = [...new Set(items.map((item) => item.category)), "all"];

const Beauty = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);
  const transition = { delay: 0.3, duration: 0.6 };

  const [menuItems, setMenuItems] = useState(
    items.filter((item) => item.category === "EYELASH EXTENSION - CLASSIC")
  );
  const [activeCategory, setActiveCategory] = useState(
    "EYELASH EXTENSION - CLASSIC"
  );
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <>
      <AnimatePresence>
        {loaded ? (
          <main className="beauty_container">
            <section className="menu section">
              <h1
                className="animated rubberBand"
                style={{
                  fontSize: "72px",
                  color: "#a84837",
                  textAlign: "center",
                  marginBottom: "2rem",
                  fontWeight: 500,
                }}
              >
                Beauty Service
              </h1>

              <CategoriesBeauty
                categories={categories}
                activeCategory={activeCategory}
                filterItems={filterItems}
              />
              <p
                className="animated wobble"
                style={{ 
                  fontSize: "18px",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "0rem",
                  fontStyle: "italic",
                }}
              >
                (*) Booking Required
              </p>
              <MenuBeauty items={menuItems} />
            </section>
            <ContentSec
              ref={ref}
              // style={{ backgroundImage: `url(${background})` }}
            >
              <Container>
                <ContentRow>
                  <ContentColumn>
                    <TextWrapper>
                      <ContentButton
                        initial={initial}
                        transition={{ ...transition, delay: 0.3 }}
                        animate={animation}
                      >
                        <a
                          target="_blank"
                          href="https://www.fresha.com/a/diamond-nails-blenheim-blenheim-14-maxwell-road-sykp7v6i/booking?menu=true"
                          style={{
                            textDecoration: "none",
                            color: "#a84837",
                          }}
                        >
                          BOOK NOW
                        </a>
                      </ContentButton>
                    </TextWrapper>
                  </ContentColumn>
                </ContentRow>
              </Container>
            </ContentSec>
          </main>
        ) : (
          <Loading text="Beauty services..." />
        )}
      </AnimatePresence>
    </>
  );
};

export default Beauty;
