import React, { useState, useEffect } from "react";
import InstaFeeds from "./InstaComponent/InstaFeeds";
import background from "./black-paper-3.jpg";
import Loading from "../Loading/Loading";
import { AnimatePresence } from "framer-motion";


const Instagram = () => {

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);
  return (
    <>
    <AnimatePresence>
      {loaded ? (
    
    <div
      id="instagram"
      style={{
        // backgroundImage: `url(${background})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100vh"
      }}
    >

<h1
                className="animated flash "
                style={{
                  fontSize: "60px",
                  color: "#a84837",
                  textAlign: "center",
                  margin: "3rem 0",                  
                  fontWeight: 500,
                }}
              >
                Follow us on Instagram{" "}
              </h1>
      <a
        href="https://www.instagram.com/diamondnailsandbeautyblenheim/"
        target="_blank"
        className="animated bounceIn "
        style={{
          fontSize: "1.5rem",
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#fff",
        }}
      >
        #towerbeauty
      </a>
      {/* <h1 className="instagram-title">Follow us on Instagram</h1>
      <a
        href="https://www.instagram.com/diamondnailsandbeautyblenheim/ "
        target="_blank"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#fff",
        }}
      >
        #diamondnailsbeauty
      </a> */}

      {/* <InstaFeeds token="IGQVJYNUZA6T2h5TVFWUEhjY3k5Y0p5REh6T0ZAnajltTkt1R0pXR19rMC1XV3haS3dvSEw5MG5Gd1J6YjRMUnFsTzhLd29XY2lxNl9CYVFRRjJpUF9aVG1DeDloYWpiZA0Y2N09Ia1BpaW0tOEdaYjVBNAZDZD" limit={12} /> */}
      <InstaFeeds
        token="IGQVJYcmhKU09sd3laYXRHZADZAoLXJnalhJeUpvaWM0dHhwejBJSXRjSXJpX1R4d3FGYXJ4QVE1WV84ZAlp4dmpyUkNwZA1ZAXQ3FYNEREb25UdlRPdEJHQVE3TlluMDR0N3A0ZADY1VEFTR0lqVXc1djZAtZAQZDZD"
        limit={12}
      />
       </div> ) : (
      <Loading text="Instagram page..." />
    )}
    </AnimatePresence>
    </>
  );
};

export default Instagram;
