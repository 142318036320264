import React from "react";
import { CardImg } from "reactstrap";
import { ScaleLoader, DotLoader, RingLoader, PropagateLoader, CircleLoader } from "react-spinners";
import Logo from "../../assets/tower/logotower.png";

const Loading = props => {
  return (
    <div
      style={{ background: "#000", width: "100vw", height: "100vh" }}
      className="animated slideInLeft d-flex align-items-center justify-content-center flex-column" 
    >
      <CardImg
        src={Logo}
        style={{
          maxWidth: "250px",
          animation: "changeColor",
          animationDuration: "5s",
          background: "#000"
        }}
      />
      <h6 className="mt-0 mb-4" style={{color: "#fff"}}>{props.text}</h6>
      <CircleLoader size={40} color="#f0cc9a" />
    </div>
  );
};

export default Loading;
