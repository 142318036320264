import React, { useState, useEffect } from "react";
import "./Nails.css";
import items from "./dataNails";
import Menu from "./Menu";
import Categories from "./Categories";
import background from "./light-yellow.jpg";
import lightorange from "./lightorange.jpg";
import lightyellow from "./yellow.avif";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  ContentButton,
  ContentColumn,
} from "./ContentStyles";
import { Container } from "../../globalStyles";
import Loading from "../Loading/Loading";
import { AnimatePresence } from "framer-motion";


const allCategories = [...new Set(items.map((item) => item.category)), "all"];

const Nails = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);
  const transition = { delay: 0.3, duration: 0.6 };
  // setInitial Items
  const [initialItems, setInitialItems] = useState(
    items.filter((item) => item.category === "NAIL EXTENSIONS")
  );
  const [menuItems, setMenuItems] = useState(initialItems);
  const [activeCategory, setActiveCategory] = useState("NAIL EXTENSIONS");
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <>
      <AnimatePresence>
        {loaded ? (
          <div className="nail_container">
            <section className="menu section">
              <h1
                className="animated bounceIn "
                style={{
                  fontSize: "72px",
                  color: "#a84837",
                  textAlign: "center",
                  marginBottom: "2rem",
                  fontWeight: 500,
                }}
              >
                Nails service{" "}
              </h1>
              <Categories
                categories={categories}
                activeCategory={activeCategory}
                filterItems={filterItems}
              />
              <Menu items={menuItems} />
            </section>
            <div class="btn-cont1 animated bounceIn delay-2s">
              <a class="btn1" href="#">
                Book Now
                <span class="line-1"></span>
                <span class="line-2"></span>
                <span class="line-3"></span>
                <span class="line-4"></span>
              </a>
            </div>

            {/* <ContentSec
              ref={ref}
              style={{ 
                // backgroundImage: `url(${lightorange})` 
            
              background: "#f0cc9e"
            }}
            >
              <Container>
                <ContentRow>
                  <ContentColumn>
                    <TextWrapper>
                      <ContentButton
                        initial={initial}
                        transition={{ ...transition, delay: 0.3 }}
                        animate={animation}
                      >
                        <a
                          target="_blank"
                          href="https://www.fresha.com/a/diamond-nails-blenheim-blenheim-14-maxwell-road-sykp7v6i/booking?menu=true"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          BOOK NOW
                        </a>
                      </ContentButton>
                    </TextWrapper>
                  </ContentColumn>
                </ContentRow>
              </Container>
            </ContentSec> */}
          </div>
        ) : (
          <Loading text="Nails services..." />
        )}
      </AnimatePresence>
    </>
  );
};

export default Nails;
