import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CardImg } from "reactstrap";
// import MiniLogo from "../../assets/img/minilogo.png";
import MiniLogo from "../../assets/img/hmm_blank.png";
import logotower from "../../assets/tower/logotower.png"
import ziczac from "../../assets/tower/ziczac.png"

import "./Navbar.css";
class Navbar extends Component {
  state = {
    home: false,
    about: false,
    skills: false,
    work: false,
    contact: false,
    experiments: false,
    facebook: false,
    instagram: false
  };

  onNavClick = e => {
    let updatedState = { ...this.state };

    for (let key in updatedState) {
      updatedState[key] = false;
    }

    this.setState(prevState => updatedState);

    const attr = e.target.getAttribute("customattribute");

    this.setState(prevState => ({ [attr]: !prevState[attr] }));
  };

  render() {
    return (
      <nav
        style={{
          height: "100%",
          width: "60px",
          position: "fixed",
          zIndex: "100",
          // backgroundColor: "#eed4b4",
          // backgroundColor: "#f0cc9a",
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${ziczac}) center `,
          top: "0",
          left: "0"
        }}
        className="d-flex flex-column align-items-center"
      >
        <div className="mt-2">
          <Link to="/">
            <img src={logotower} className="logotower" />

          </Link>
        </div>
        <div
          style={{ width: "100%", height: "80%" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {/* <Link name="home" className="mt-auto" to="/">
            <i
              onClick={this.onNavClick}
              style={this.state.home ? { color: "#A30000" } : null}
              customattribute="home"
              className="fas fa-home"
            />
          </Link> */}
          {/* <Link name="about" className="mt-auto" to="/about">
            <i
              onClick={this.onNavClick}
              style={this.state.about ? { color: "#A30000" } : null}
              customattribute="about"
              className="far fa-user"
            />
          </Link>
          <Link name="nail" className="mt-4" to="/nails">
            <i
              onClick={this.onNavClick}
              style={this.state.skills ? { color: "#A30000" } : null}
              customattribute="skills"
              className="fas fa-star"
            />
          </Link> */}
          {/* <Link name="beauty" className="mt-4" to="/beauty">
            <i
              onClick={this.onNavClick}
              style={this.state.work ? { color: "#A30000" } : null}
              customattribute="work"
              className="fa fa-heart"
            />
          </Link> */}
          {/* <Link className="mt-4" to="/experiments">
            <i
              onClick={this.onNavClick}
              style={this.state.experiments ? { color: "#A30000" } : null}
              customattribute="experiments"
              className="fas fa-flask"
            />
          </Link> */}
          {/* <Link className="mt-4" to="/facebook">
            <i
              onClick={this.onNavClick}
              style={this.state.facebook ? { color: "#A30000" } : null}
              customattribute="facebook"
              className="fab fa-facebook"
            />
          </Link>
          <Link className="mt-4" to="/instagram">
            <i
              onClick={this.onNavClick}
              style={this.state.instagram ? { color: "#A30000" } : null}
              customattribute="instagram"
              className="fab fa-instagram"
            />
          </Link> */}
          <Link className="mt-auto" to="/contact">
            <i
              onClick={this.onNavClick}
              style={this.state.contact ? { color: "#A30000" } : null}
              customattribute="contact"
              className="far fa-envelope"
            />
          </Link>

          {/* <a className="mt-auto" href="https://www.facebook.com/" target="_blank">
          <i class="fab fa-facebook"></i>
          </a>
          <a className="mt-4" href="https://www.instagram.com/" target="_blank">
          <i class="fab fa-instagram"></i>
          </a>
          <a className="mt-4" href="https://www.youtube.com/" target="_blank">
            <i class="fab fa-youtube"></i>
          </a> */}
        </div>
      </nav>
    );
  }
}

export default Navbar;
